import React from "react"

const ConfirmPageButtons = props => {
    const { processing, handleOnBackPage } = props
    return (
        <div className={`button-wrap ${processing ? 'transparent' : ''}`}>
            <button
                className="back-button"
                onClick={() => handleOnBackPage()}
            >
                <span>戻る</span>
                <span className="bracket" />
            </button>
            <button type="submit" >
                <span>送信する</span>
                <span className="bracket" />
            </button>
        </div>
    );
}

export default ConfirmPageButtons
