import React, { useState } from "react"
import { useForm } from "react-hook-form"
import FormPersonalInformation from "../formPersonalInformation";

const PrForm = props => {
    const [ agreementState, setAgreementState] = useState(false)
    const { contactState, handleOnNextPage } = props
    const {
        register,
        handleSubmit,
        formState: { errors },
        getValues,
    } = useForm();

    const handleOnAgreementChecked = () => {
        setAgreementState(!agreementState);
    }

    const onSubmit = data => {
        handleOnNextPage(data);
    }

    if (contactState.selectedType === 'pr' && contactState.step === 'form') {
        return (
            <section className="form-wrap">
                <h3>PR・メディア関係者様のお問い合わせ</h3>
                <form onSubmit={handleSubmit(onSubmit)} className="no-border">
                    <h4>ご依頼内容</h4>
                    <div className="column">
                        <div className="name">
                            ご依頼の種類<span className="required">必 須</span>
                        </div>
                        <div className="form-control-radio">
                            <input type="radio" id="type_interview"
                                   name="contact_type"
                                   value="取材の申し込み"
                                   {...register("contact_type",{ required: true })}
                                   />
                            <label htmlFor="type_interview" className="radio-check">取材の申し込み</label>
                            <input type="radio"
                                   id="type_other"
                                   name="contact_type"
                                   value="その他"
                                   {...register("contact_type",{ required: true })}
                                   />
                            <label htmlFor="type_other" className="radio-check">その他</label>
                            {errors.contact_type && (
                                <span className="error-message">ご依頼の種類を選択してください。</span>
                            )}
                        </div>
                    </div>
                    <div className="column no-border">
                        <div className="name">
                            内容<span className="required">必 須</span>
                            <div className="notification">※800字以下で入力してください。</div>
                        </div>
                        <div className="form-control-text">
                                        <textarea name="comment"
                                                  maxLength="800"
                                                  rows={16}
                                                  className={`${errors.comment ? 'error' : ''}`}
                                                  {...register("comment",{ required: true })}
                                                  ></textarea>
                            {errors.comment && (
                                <span className="error-message">内容を入力してください。</span>
                            )}
                        </div>
                    </div>
                    <h4>媒体情報</h4>
                    <div className="column">
                        <div className="name">
                            媒体名<span className="required">必 須</span>
                        </div>
                        <div className="form-control-text">
                            <input type="text"
                                   name="media_name"
                                   className={`${errors.media_name ? 'error' : ''}`}
                                   {...register("media_name",{ required: true })}
                            />
                            {errors.media_name && (
                                <span className="error-message">媒体名を入力してください。</span>
                            )}
                        </div>
                    </div>
                    <div className="column">
                        <div className="name">
                            会社名<span className="required">必 須</span>
                        </div>
                        <div className="form-control-text">
                            <input type="text"
                                   name="company_name"
                                   className={`${errors.company_name ? 'error' : ''}`}
                                   {...register("company_name",{ required: true })}
                            />
                            {errors.company_name && (
                                <span className="error-message">会社名を入力してください。</span>
                            )}
                        </div>
                    </div>
                    <div className="column no-border">
                        <div className="name">
                            掲載号・発売日程
                        </div>
                        <div className="form-control-text">
                            <input type="text"
                                   name="published_issue"
                                   placeholder="掲載号"
                                   className="text-half"
                                   {...register("published_issue")}
                                   />
                            <input type="text"
                                   name="release_date"
                                   placeholder="発売日程"
                                   className="text-half"
                                   {...register("release_date")}
                                   />
                        </div>
                    </div>
                    <h5>企画について</h5>
                    <div className="column">
                        <div className="name">
                            企画名
                        </div>
                        <div className="form-control-text">
                            <input type="text"
                                   name="plan_name"
                                   {...register("plan_name")}
                            />
                        </div>
                    </div>
                    <div className="column">
                        <div className="name">
                            ボリューム
                        </div>
                        <div className="form-control-text">
                            <input type="text"
                                   name="plan_volume"
                                   {...register("plan_volume")}
                            />
                        </div>
                    </div>
                    <div className="column no-border">
                        <div className="name">
                            企画内容<span className="required">必 須</span>
                            <div className="notification">※800字以下で入力してください。</div>
                        </div>
                        <div className="form-control-text">
                                        <textarea name="plan_contents"
                                                  maxLength="800"
                                                  rows={16}
                                                  className={`${errors.plan_contents ? 'error' : ''}`}
                                                  {...register("plan_contents",{ required: true })}
                                                  ></textarea>
                            {errors.plan_contents && (
                                <span className="error-message">企画内容を入力してください。</span>
                            )}
                        </div>
                    </div>
                    <h5>取材をご希望の場合</h5>
                    <div className="column no-border">
                        <div className="name">
                            取材の希望期間
                        </div>
                        <div className="form-control-text">
                            <div className="input-date-wrap text-half">
                                <label htmlFor="interview_from_date">From</label>
                                <input type="date"
                                       name="interview_from_date"
                                       id="interview_from_date"
                                       className="date-half"
                                       {...register("interview_from_date")}
                                       />
                            </div>
                            <div className="input-date-wrap text-half">
                                <label htmlFor="interview_to_date">To</label>
                                <input type="date"
                                       name="interview_to_date"
                                       id="interview_to_date"
                                       className="date-half"
                                       {...register("interview_to_date")}
                                />
                            </div>
                        </div>
                    </div>
                    <h4>ご依頼者様情報</h4>
                    <FormPersonalInformation register={register}
                                             errors={errors}
                                             getValues={getValues}
                                             agreementState={agreementState}
                                             handleOnAgreementChecked={handleOnAgreementChecked}/>
                    <div className="button-wrap">
                        <button type="submit" disabled={!agreementState}>
                            送信確認
                            <span className="bracket"></span>
                        </button>
                    </div>
                </form>
            </section>
        )
    }
}

export default PrForm
