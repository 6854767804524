import React from "react"

const FormPersonalInformation = props => {
    const { register,
        errors,
        getValues,
        agreementState,
        handleOnAgreementChecked} = props;
    return (
        <div>
            <div className="column">
                <div className="name">
                    ご担当者様（漢字）<span className="required">必 須</span>
                </div>
                <div className="form-control-text">
                    <input type="text"
                           name="last_name"
                           placeholder="姓"
                           className={`${errors.last_name ? 'error' : ''} text-half`}
                           {...register("last_name",{ required: true })}
                    />
                    <input type="text"
                           name="first_name"
                           placeholder="名"
                           className={`${errors.first_name ? 'error' : ''} text-half`}
                           {...register("first_name",{ required: true })}
                    />
                    {(errors.last_name || errors.first_name) && (
                        <span className="error-message">ご担当者様名を入力してください。</span>
                    )}
                </div>
            </div>
            <div className="column">
                <div className="name">
                    ご担当者様（カナ）<span className="required">必 須</span>
                </div>
                <div className="form-control-text">
                    <input type="text"
                           name="last_name_ruby"
                           placeholder="セイ"
                           className={`${errors.last_name_ruby ? 'error' : ''} text-half`}
                           {...register("last_name_ruby",{ required: true })}
                    />
                    <input type="text"
                           name="first_name_ruby"
                           placeholder="メイ"
                           className={`${errors.first_name_ruby ? 'error' : ''} text-half`}
                           {...register("first_name_ruby",{ required: true })}
                    />
                    {(errors.last_name_ruby || errors.first_name_ruby) && (
                        <span className="error-message">ご担当者様名（カナ）を入力してください。</span>
                    )}
                </div>
            </div>
            <div className="column">
                <div className="name">
                    メールアドレス<span className="required">必 須</span>
                </div>
                <div className="form-control-text">
                    <input type="email"
                           name="email"
                           className={`${errors.email ? 'error' : ''}`}
                           {...register("email",{ required: true })}/>
                    {errors.email && (
                        <span className="error-message">メールアドレスを入力してください。</span>
                    )}
                </div>
            </div>
            <div className="column">
                <div className="name">
                    メールアドレス（確認）<span className="required">必 須</span>
                </div>
                <div className="form-control-text">
                    <input type="email"
                           name="email_confirm"
                           className={`${errors.email_confirm ? 'error' : ''}`}
                           {...register("email_confirm", {
                               validate: value => {
                                   return (
                                       value === getValues("email") ||
                                       "メールアドレス（確認 ）が一致しません"
                                   )
                               },
                           })}
                    />
                    {errors.email_confirm &&
                        errors.email_confirm.type === "validate" && (
                            <span className="error-message">{errors.email_confirm.message}</span>
                        )
                    }
                </div>
            </div>
            <div className="column">
                <div className="name">
                    電話番号
                </div>
                <div className="form-control-text">
                    <input type="tel"
                           name="tel"
                           {...register("tel")}
                    />
                </div>
            </div>
            <div className="column">
                <div className="name">
                    個人情報の取り扱いへの同意確認
                </div>
                <div className="form-control-check">
                    <div>
                        <a href="/policy" target="_blank" className="link-text">
                            個人情報のお取り扱いに関する同意事項
                            <img
                                src="https://stgokulabweb.blob.core.windows.net/images/alliance/icon-open-link.svg"
                                alt="Open Link"/>
                        </a>
                    </div>
                    <input type="checkbox"
                           id="is_agreement_policy"
                           name="is_agreement_policy"
                           checked={agreementState}
                           onChange={() => handleOnAgreementChecked()}
                           required/>
                    <label htmlFor="is_agreement_policy"
                           className="checkbox">個人情報のお取り扱いに関する同意事項を読み、内容に同意します。</label>
                </div>
            </div>
        </div>
    );
}

export default FormPersonalInformation
