import React, { useState } from "react"
import axios from "axios";
import Loading from "../../loading";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";
import ConfirmPersonalInformation from "../confirmPersonalInformation";
import ConfirmPageButtons from "../confirmPageButtons";

const OtherConfirm = React.memo(props => {
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [ errorState, setErrorState] = useState(false);
    const {
        contactState,
        formData,
        handleOnBackPage,
        handleOnNextPage,
    } = props
    const [processing, setProcessing] = useState(false);
    const handleSubmit = async (event) => {
        setErrorState(false);
        event.preventDefault();
        event.stopPropagation();

        if (processing) return;
        setProcessing(true);

        const url = process.env.SEND_MAIL_OTHER_URI;
        try {
            formData.token = await executeRecaptcha("contact");
            await axios.post(url, formData);
            handleOnNextPage(formData);
        } catch (error) {
            setProcessing(false);
            console.error('送信エラー:', error);
            setErrorState(true);
        }
    }
    if (contactState.selectedType === 'other' && contactState.step === 'confirm') {
        window.scroll({ top: 0, behavior: "auto" })
        return (
            <section className="form-wrap">
                <h3>お問い合わせ内容</h3>
                <form onSubmit={handleSubmit}>
                    <div className={`error-message ${errorState ? '' : 'hidden'}`}>データの送信に失敗しました。しばらく経ってから再度お試しください。</div>
                    <Loading processing={processing}/>
                    <div className="column">
                        <div className="name">ご意見・ご質問</div>
                        <div>{formData.comment}</div>
                    </div>
                    <div className="column">
                        <div className="name">会社名</div>
                        <div>{formData.company_name}</div>
                    </div>
                    <div className="column">
                        <div className="name">部署名</div>
                        <div>{formData.section_name}</div>
                    </div>
                    <ConfirmPersonalInformation formData={formData}/>
                    <ConfirmPageButtons processing={processing}
                                        handleOnBackPage={handleOnBackPage}  />
                </form>
            </section>
        )
    }
    return ""
})

export default OtherConfirm
