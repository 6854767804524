import React, { useState } from "react"
import { useForm } from "react-hook-form"
import FormPersonalInformation from "../formPersonalInformation";

const ServiceForm = props => {
    const [ agreementState, setAgreementState] = useState(false)
    const { contactState, handleOnNextPage } = props
    const {
        register,
        handleSubmit,
        formState: { errors },
        getValues,
    } = useForm();

    const handleOnAgreementChecked = () => {
        setAgreementState(!agreementState);
    }

    const onSubmit = data => {
        handleOnNextPage(data);
    }

    if (contactState.selectedType === 'service' && contactState.step === 'form') {
        return (
            <section className="form-wrap">
                <h3>サービスに関するご意見・お問い合わせ</h3>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="column">
                        <div className="name">
                            ご意見・ご質問<span className="required">必 須</span>
                            <div className="notification">※800字以下で入力してください。</div>
                        </div>
                        <div className="form-control-text">
                                        <textarea name="comment"
                                                  maxLength="800"
                                                  className={`${errors.comment ? 'error' : ''}`}
                                                  rows={16}
                                                  {...register("comment",{ required: true })}
                                                  ></textarea>
                            {errors.comment && (
                                <span className="error-message">ご意見・ご質問を入力してください。</span>
                            )}
                        </div>
                    </div>
                    <div className="column">
                        <div className="name">
                            店舗名<span className="required">必 須</span>
                        </div>
                        <div className="form-control-text">
                            <input type="text"
                                   name="shop_name"
                                   className={`${errors.shop_name ? 'error' : ''}`}
                                   {...register("shop_name",{ required: true })}
                            />
                            {errors.shop_name && (
                                <span className="error-message">店舗名を入力してください。</span>
                            )}
                        </div>
                    </div>
                    <FormPersonalInformation register={register}
                                             errors={errors}
                                             getValues={getValues}
                                             agreementState={agreementState}
                                             handleOnAgreementChecked={handleOnAgreementChecked}/>
                    <div className="button-wrap">
                        <button type="submit" disabled={!agreementState}>
                            送信確認
                            <span className="bracket" />
                        </button>
                    </div>
                </form>
            </section>
        )
    }
}

export default ServiceForm
