import React, { useState } from "react"
import { useForm } from "react-hook-form"
import FormPersonalInformation from "../formPersonalInformation";

const OpForm = props => {
    const [ agreementState, setAgreementState] = useState(false)
    const { contactState, handleOnNextPage } = props
    const {
        register,
        handleSubmit,
        formState: { errors },
        getValues,
    } = useForm();

    const handleOnAgreementChecked = () => {
        setAgreementState(!agreementState);
    }

    const onSubmit = data => {
        handleOnNextPage(data);
    }

    if (contactState.selectedType === 'op' && contactState.step === 'form') {
        return (
            <section className="form-wrap">
                <h3>コインランドリー運営代行についてのお問い合わせ</h3>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="column">
                        <div className="name">
                            ご要望に近いものをお選びください<span className="required">必 須</span>
                        </div>
                        <div className="form-control-check">
                            <div>
                                <input type="checkbox" id="type_existing"
                                       name="requirement"
                                       value="営業中の店舗の運営について相談したい"
                                       {...register("requirement",{ required: true })}
                                />
                                <label htmlFor="type_existing" className="checkbox">営業中の店舗の運営について相談したい</label>
                            </div>
                            <div>
                                <input type="checkbox"
                                       id="type_new"
                                       name="requirement"
                                       value="オープン予定の店舗の運営について相談したい"
                                       {...register("requirement",{ required: true })}
                                />
                                <label htmlFor="type_new" className="checkbox">オープン予定の店舗の運営について相談したい</label>
                            </div>
                            <div>
                                <input type="checkbox"
                                       id="type_renewal"
                                       name="requirement"
                                       value="店舗のリニューアルについて相談したい"
                                       {...register("requirement",{ required: true })}
                                />
                                <label htmlFor="type_renewal" className="checkbox">店舗のリニューアルについて相談したい</label>
                            </div>
                            <div className="w-100">
                                <input type="checkbox"
                                       id="type_other"
                                       name="requirement"
                                       value="その他"
                                       {...register("requirement",{ required: true })}
                                />
                                <label htmlFor="type_other" className="checkbox">その他</label>
                            </div>
                            {errors.requirement && (
                                <span className="error-message">ご要望に近いものをお選びください。</span>
                            )}
                        </div>
                    </div>
                    <div className="column">
                        <div className="name">
                            その他を選択された方はこちらに詳細をご記載ください
                            <div className="notification">※800字以下で入力してください。</div>
                        </div>
                        <div className="form-control-text">
                                        <textarea name="comment"
                                                  maxLength="800"
                                                  rows={16}
                                                  {...register("comment")}
                                        ></textarea>
                        </div>
                    </div>
                    <div className="column">
                        <div className="name">
                            導入を検討されている店舗の所在地<span className="required">必 須</span>
                        </div>
                        <div className="form-control-check">
                            <div>
                                <input type="checkbox" id="area_hokkaido"
                                       name="area"
                                       value="北海道"
                                       {...register("area",{ required: true })}
                                />
                                <label htmlFor="area_hokkaido" className="checkbox">北海道</label>
                            </div>
                            <div>
                                <input type="checkbox" id="area_tohoku"
                                       name="area"
                                       value="東北"
                                       {...register("area",{ required: true })}
                                />
                                <label htmlFor="area_tohoku" className="checkbox">東北</label>
                            </div>
                            <div>
                                <input type="checkbox" id="area_kita_kanto"
                                       name="area"
                                       value="北関東"
                                       {...register("area",{ required: true })}
                                />
                                <label htmlFor="area_kita_kanto" className="checkbox">北関東</label>
                            </div>
                            <div>
                                <input type="checkbox" id="area_minami_kanto"
                                       name="area"
                                       value="南関東"
                                       {...register("area",{ required: true })}
                                />
                                <label htmlFor="area_kita_kanto" className="checkbox">南関東</label>
                            </div>
                            <div>
                                <input type="checkbox" id="area_koushinetsu"
                                       name="area"
                                       value="甲信越"
                                       {...register("area",{ required: true })}
                                />
                                <label htmlFor="area_koushinetsu" className="checkbox">甲信越</label>
                            </div>
                            <div>
                                <input type="checkbox" id="area_hokuriku"
                                       name="area"
                                       value="北陸"
                                       {...register("area",{ required: true })}
                                />
                                <label htmlFor="area_hokuriku" className="checkbox">北陸</label>
                            </div>
                            <div>
                                <input type="checkbox" id="area_tokai"
                                       name="area"
                                       value="東海"
                                       {...register("area",{ required: true })}
                                />
                                <label htmlFor="area_tokai" className="checkbox">東海</label>
                            </div>
                            <div>
                                <input type="checkbox" id="area_kinki"
                                       name="area"
                                       value="近畿"
                                       {...register("area",{ required: true })}
                                />
                                <label htmlFor="area_kinki" className="checkbox">近畿</label>
                            </div>
                            <div>
                                <input type="checkbox" id="area_chugoku"
                                       name="area"
                                       value="中国・四国"
                                       {...register("area",{ required: true })}
                                />
                                <label htmlFor="area_chugoku" className="checkbox">中国・四国</label>
                            </div>
                            <div>
                                <input type="checkbox" id="area_kyushu"
                                       name="area"
                                       value="九州"
                                       {...register("area",{ required: true })}
                                />
                                <label htmlFor="area_kyushu" className="checkbox">九州</label>
                            </div>
                            {errors.area && (
                                <span className="error-message">導入を検討されている店舗の所在地を選択してください。</span>
                            )}
                        </div>
                    </div>
                    <div className="column">
                        <div className="name">
                            会社名
                        </div>
                        <div className="form-control-text">
                            <input type="text"
                                   name="company_name"
                                   className="text-half"
                                   {...register("company_name")}
                            />
                        </div>
                    </div>
                    <div className="column">
                        <div className="name">
                            部署名
                        </div>
                        <div className="form-control-text">
                            <input type="text"
                                   name="section_name"
                                   className="text-half"
                                   {...register("section_name")}
                            />
                        </div>
                    </div>
                    <FormPersonalInformation register={register}
                                             errors={errors}
                                             getValues={getValues}
                                             agreementState={agreementState}
                                             handleOnAgreementChecked={handleOnAgreementChecked}/>
                    <div className="button-wrap">
                        <button type="submit" disabled={!agreementState}>
                            送信確認
                            <span className="bracket"></span>
                        </button>
                    </div>
                </form>
            </section>
        )
    }
}

export default OpForm
