import React from "react"

const ConfirmPersonalInformation = props => {
    const { formData } = props
    return (
        <div>
            <div className="column">
                <div className="name">ご担当者様（漢字）</div>
                <div>{formData.last_name} {formData.first_name}</div>
            </div>
            <div className="column">
                <div className="name">ご担当者様（カナ）</div>
                <div>{formData.last_name_ruby} {formData.first_name_ruby}</div>
            </div>
            <div className="column">
                <div className="name">メールアドレス</div>
                <div>{formData.email}</div>
            </div>
            <div className="column">
                <div className="name">電話番号</div>
                <div>{formData.tel}</div>
            </div>
        </div>
    );
}

export default ConfirmPersonalInformation
