import React, { useState } from "react"
import { useForm } from "react-hook-form"
import FormPersonalInformation from "../formPersonalInformation";

const OpenForm = props => {
    const [ agreementState, setAgreementState] = useState(false)
    const { contactState, handleOnNextPage } = props
    const {
        register,
        handleSubmit,
        formState: { errors },
        getValues,
    } = useForm();

    const handleOnAgreementChecked = () => {
        setAgreementState(!agreementState);
    }

    const onSubmit = data => {
        handleOnNextPage(data);
    }

    if (contactState.selectedType === 'open' && contactState.step === 'form') {
        return (
            <section className="form-wrap">
                <h3>ランドリー出店支援・FC加盟についてのお問い合わせ</h3>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="column">
                        <div className="name">
                            区分<span className="required">必 須</span>
                        </div>
                        <div className="form-control-radio">
                            <input type="radio" id="type_personal"
                                   name="business_form"
                                   value="個人"
                                   {...register("business_form",{ required: true })}
                                   />
                            <label htmlFor="type_personal" className="radio-check">個人</label>
                            <input type="radio"
                                   id="type_company"
                                   name="business_form"
                                   value="法人"
                                   {...register("business_form",{ required: true })}
                                   />
                            <label htmlFor="type_company" className="radio-check">法人</label>
                            {errors.business_form && (
                                <span className="error-message">区分を選択してください。</span>
                            )}
                        </div>
                    </div>
                    <div className="column">
                        <div className="name">
                            お問合せ業態<span className="required">必 須</span>
                        </div>
                        <div className="form-control-check">
                            <input type="checkbox" id="type_unmanned"
                                   name="industry"
                                   value="セルフランドリー（無人店）"
                                   {...register("industry",{ required: true })}
                                   />
                            <label htmlFor="type_unmanned" className="checkbox">セルフランドリー（無人店）</label>
                            <input type="checkbox"
                                   id="type_manned"
                                   name="industry"
                                   value="セルフランドリー（複合店）"
                                   {...register("industry",{ required: true })}
                                   />
                            <label htmlFor="type_manned" className="checkbox">セルフランドリー（複合店）</label>
                            <input type="checkbox"
                                   id="type_other"
                                   name="industry"
                                   value="その他"
                                   {...register("industry",{ required: true })}
                                   />
                            <label htmlFor="type_other" className="checkbox">その他</label>
                            {errors.industry && (
                                <span className="error-message">お問合せ業態を選択してください。</span>
                            )}
                        </div>
                    </div>
                    <div className="column">
                        <div className="name">
                            セルフランドリーの経営、運営のご経験<span className="required">必 須</span>
                        </div>
                        <div className="form-control-radio">
                            <input type="radio" id="has_experience"
                                   name="has_experience"
                                   value="あり"
                                   {...register("has_experience",{ required: true })}
                                   />
                            <label htmlFor="has_experience" className="radio-check">あり</label>
                            <input type="radio"
                                   id="does_not_have_experience"
                                   name="has_experience"
                                   value="なし"
                                   {...register("has_experience",{ required: true })}
                                   />
                            <label htmlFor="does_not_have_experience" className="radio-check">なし</label>
                            {errors.has_experience && (
                                <span className="error-message">セルフランドリーの経営、運営のご経験を選択してください。</span>
                            )}
                        </div>
                    </div>
                    <div className="column">
                        <div className="name">
                            出店予定物件の有無<span className="required">必 須</span>
                        </div>
                        <div className="form-control-radio">
                            <input type="radio" id="has_property"
                                   name="has_property"
                                   value="あり"
                                   {...register("has_property",{ required: true })}
                                   />
                            <label htmlFor="has_property" className="radio-check">あり</label>
                            <input type="radio"
                                   id="does_not_have_property"
                                   name="has_property"
                                   value="なし"
                                   {...register("has_property",{ required: true })}
                                   />
                            <label htmlFor="does_not_have_property" className="radio-check">なし</label>
                            {errors.has_property && (
                                <span className="error-message">出店予定物件の有無を選択してください。</span>
                            )}
                        </div>
                    </div>
                    <div className="column">
                        <div className="name">
                            出店希望エリア<span className="required">必 須</span>
                        </div>
                        <div className="form-control-text">
                            <input type="text"
                                   name="area"
                                   className={`${errors.area ? 'error' : ''} text-half`}
                                   {...register("area",{ required: true })}
                            />
                            {errors.area && (
                                <span className="error-message">出店希望エリアを入力してください。</span>
                            )}
                        </div>
                    </div>
                    <div className="column">
                        <div className="name">
                            開店ご希望時期<span className="required">必 須</span>
                        </div>
                        <div className="form-control-text">
                            <input type="text"
                                   name="opening_date"
                                   className={`${errors.opening_date ? 'error' : ''} text-half`}
                                   {...register("opening_date",{ required: true })}
                            />
                            {errors.opening_date && (
                                <span className="error-message">開店ご希望時期を入力してください。</span>
                            )}
                        </div>
                    </div>
                    <div className="column">
                        <div className="name">
                            ご相談内容
                            <div className="notification">※800字以下で入力してください。</div>
                        </div>
                        <div className="form-control-text">
                                        <textarea name="comment"
                                                  maxLength="800"
                                                  rows={16}
                                                  {...register("comment")}
                                                  ></textarea>
                        </div>
                    </div>
                    <div className="column">
                        <div className="name">
                            会社名
                        </div>
                        <div className="form-control-text">
                            <input type="text"
                                   name="company_name"
                                   className="text-half"
                                   {...register("company_name")}
                            />
                        </div>
                    </div>
                    <div className="column">
                        <div className="name">
                            部署名
                        </div>
                        <div className="form-control-text">
                            <input type="text"
                                   name="section_name"
                                   className="text-half"
                                   {...register("section_name")}
                            />
                        </div>
                    </div>
                    <FormPersonalInformation register={register}
                                             errors={errors}
                                             getValues={getValues}
                                             agreementState={agreementState}
                                             handleOnAgreementChecked={handleOnAgreementChecked}/>
                    <div className="button-wrap">
                        <button type="submit" disabled={!agreementState}>
                            送信確認
                            <span className="bracket"></span>
                        </button>
                    </div>
                </form>
            </section>
        )
    }
}

export default OpenForm
