import React from "react"

const InitBlankPage = props => {
    const { contactState } = props

    if (contactState.selectedType === 'init') {
        return (
            <section className="blank-page">
            </section>
        )
    }
}

export default InitBlankPage
